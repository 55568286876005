import React from "react"
import Base from "./Base"

// import { Helmet } from "react-helmet";

import PartnerSingle from "../Partners/PartnerSingle";

const PartnerSingleTemplate = ({ pageContext }) =>  {
    
  return (
    <Base context={pageContext}>
      <PartnerSingle data={pageContext} />
    </Base>
  )
}

export default PartnerSingleTemplate