import React from "react"

import styled from "styled-components"

import ThemeDefault from "../theme/ThemeDefault";

import Medal from '../../assets/images/icons/medal.svg'
import Call from '../../assets/images/icons/call.svg'
import Email from '../../assets/images/icons/email.svg'
import Pin from '../../assets/images/icons/pin.svg'

import { GatsbyImage } from "gatsby-plugin-image"

import {device} from "../theme/MediaQueries"

import ParseHTML, { ParseAndRemoveTags } from "../helpers/ParseHTML";

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .hero-inner {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;

      & .title-col {
        align-items: center;
      }
      & h1  {
        text-align: center;
        & *  {
          text-align: center;
        }
      }
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.4;
      letter-spacing: -1px;
      margin-bottom: 24px;
      text-align: left;
      font-weight: 500;
      
      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-col {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      width: 60%;

      @media ${device.MXmd} {
        padding: 24px 0;
        width: 60%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
      @media ${device.MXxs} {
        width: 100%;
      }
    }

    .hero-text {
      text-align: left;
      margin-bottom: 32px;
      font-size: var(--font-2);
      padding-right: 64px;

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .image-wrapper {
      background-color: #fff;
      width: 100%;
      padding-bottom: 70%;
      position: relative;


      .gatsby-image-wrapper {
        position: absolute !important;
        width: 80% !important;
        height: 80% !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        
        img {
          object-fit: contain !important;
          object-position: center !important;
        }
      }

    }
    .web a {
      color: ${ThemeDefault.black};
      font-weight: 600;
    }

    .image-col {
      width: 50%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
      @media ${device.MXmd} {
        width: 50%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }

      & .image-inner {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        &>div {
          position: absolute;
        }
        .player {
          width: 100%;
          height: 100%;
        }
      }
    }

  }


`

const SectionContentComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

  &:last-of-type {
    padding-bottom: clamp(3.2rem,1.28rem + 4vw, 6.4rem);
  }

  .c-margin-top-m {
    margin-top: clamp(3.2rem,1.28rem + 4vw, 6.4rem);
  }

  .inner-div {
    padding: clamp(3.2rem,1.28rem + 4vw, 6.4rem) 24px 0 24px;
  }

  .contact-info {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;

    .icon {
      width: 24px;
      margin-right: 8px;
    }
  }
  
  h2 {
    font-family: ${ThemeDefault.fontSecondary};
    margin-bottom: 24px;
  }

  .contact-col {
    .inner-wrap {
      padding: 24px;
      background-color: ${ThemeDefault.offWhite}
    }
  }

`

const PartnerLevel = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${ThemeDefault.offWhite};

  .inner-div {
    padding: 32px 24px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: ${ThemeDefault.font__1};
    font-weight: 700;
    letter-spacing: 1px;
  }

  svg {
    width: 32px;
    margin-right: 8px;
    height: auto;
  }

  &.gold {
    color: #d6b80f;
  }

  &.silver {
    color: #a7a4a4;
  }

  &.platinum {
    color: #8a8a8a;
  }

`
const PartnerSingle = ( data ) => {

  const childImageSharp = data.data.partnerInformation.partnerHeroImage ? data.data.partnerInformation.partnerHeroImage.imageFile.childImageSharp : null
  const publicURL = data.data.partnerInformation.partnerHeroImage ? data.data.partnerInformation.partnerHeroImage.imageFile.publicURL : null

  const partnerURL = data.data.partnerInformation.url ? (data.data.partnerInformation.url.indexOf('http') === 0 ? data.data.partnerInformation.url : `https://${data.data.partnerInformation.url}`) : ''

  return (
    <>
      <SectionComponent key={`hero-${data.index}`} cssClass="m-top" bgColour={data.data.partnerInformation.sectionBgColour}>
        <div className="inner-div wrapper hero-inner">
          <div className={`image-col`}>
            <div className="image-wrapper">
            {
              childImageSharp ? 
              <GatsbyImage alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} image={childImageSharp.gatsbyImageData} className={data.data.className} /> :
              <img alt={data.data.altText ? data.data.altText : data.data.title.replace(/(<([^>]+)>)/gi, "")} src={publicURL} className={data.data.className} /> 
            }
            </div>
          </div>
          <div className="title-col">
            
          {data.data.title && <div className="hero-title"><h1>{ParseAndRemoveTags(data.data.title)}</h1></div>}
          {data.data.partnerInformation.url && <div className="web"><a href={partnerURL} target="_blank" rel="noreferrer">{(data.data.partnerInformation.url)}</a></div>}
          
          </div>

        </div>
      </SectionComponent>
      
      {data.data.partnerInformation.partnerLevel !== 'none' && 
        <PartnerLevel cssClass={`partnerLevel ${data.data.partnerInformation.partnerLevel}`}>
          <div className="inner-div">
            <Medal /> {data.data.partnerInformation.partnerLevel} PARTNER
          </div>
        </PartnerLevel>
      }
      {data.data.partnerInformation.aboutPartner &&
      <SectionContentComponent className="about">
        <div className="inner-div ">
          <div className="title"><h2>About</h2></div>
          {ParseHTML(data.data.partnerInformation.aboutPartner)}
        </div>
      </SectionContentComponent>
      }
      
      <SectionContentComponent className="about">
        <div className="inner-div c-columns-2 c-columns-m-1">
          <div className="col">
            {data.data.partnerInformation.serviceInfo &&
            <div className="inner-wrap">
              <div className="title"><h2>Geographies Serviced</h2></div>
              <div className="serviceText">{ParseHTML(data.data.partnerInformation.serviceInfo)}</div>
            </div>
            }
            {data.data.partnerInformation.posSupported && data.data.partnerInformation.posSupported.length > 0 &&
              <div className="inner-wrap c-margin-top-m">
                <div className="title"><h2>POS Systems Supported</h2></div>
                {
                  data.data.partnerInformation.posSupported.map((pos, index) => { 
                    return (
                      <div className="serviceText" key={`pos-${index}`}>{pos.pos}</div>
                    )
                  })
                }
              </div>
            }
          </div>
          {
            (data.data.partnerInformation.address !== '' && 
            data.data.partnerInformation.telephone !== ''  &&
            data.data.partnerInformation.telephoneAlt !== ''  &&
            data.data.partnerInformation.email !== ''  ) &&
          <div className="col contact-col">
            <div className="inner-wrap">
              <div className="title"><h2>Contact Information</h2></div>
              {data.data.partnerInformation.address && <div className="contact-info"><div className="icon"><Pin /></div> {data.data.partnerInformation.address}</div>}
              {data.data.partnerInformation.telephone && <div className="contact-info"><div className="icon"><Call /></div> {data.data.partnerInformation.telephone} {data.data.partnerInformation.telephoneAlt && `or ${data.data.partnerInformation.telephoneAlt}`}</div>}
              {data.data.partnerInformation.email && <div className="contact-info"><div className="icon"><Email /></div> {data.data.partnerInformation.email}</div>}
            </div>
          </div>
          }
        </div>
      </SectionContentComponent>

    </>
  );
}

export default PartnerSingle
